<template>
  <div>
    <div class="row">
      <div class="col-md-12" v-if="isTerminNach24">
        <ReiseterminViewCardFlugdaten
          :isInitialLoading="isInitialLoading"
          :reiseterminObj="reiseterminObj"
        ></ReiseterminViewCardFlugdaten>
      </div>
      <div class="col-md-12" v-if="isTerminIn24">
        <ReiseterminViewCardSheetdata :reiseterminObj="reiseterminObj" />
      </div>
      <div class="col-md-6">
        <ReiseterminCardAufgaben :reiseterminObj="reiseterminObj"></ReiseterminCardAufgaben>
      </div>
      <div class="col-md-6">
        <template v-if="reiseterminObj">
          <ReiseterminCardCommentBase
            :reisekuerzel="reiseterminObj.reise.reisekuerzel"
            :reiseterminId="reiseterminObj.id"
            :startdatum="reiseterminObj.startdatum"
            :initalComments="reiseterminObj.comments"
            :reiseterminObj="reiseterminObj"
            :showDepartmentButtons="true"
            :isInputFieldExpanded="false"
          ></ReiseterminCardCommentBase>
        </template>
        <template v-else>
          <div class="card">
            <div class="card-header">
              <b-skeleton class="m-auto" height="100%" width="100%"></b-skeleton>
            </div>
            <div class="card-body">
              <b-skeleton class="m-auto" height="200px" width="100%"></b-skeleton>
            </div>
          </div>
        </template>
      </div>

      <div class="col-md-12 gutter-b">
        <template v-if="reiseterminObj">
          <div>
            <ReiseterminCardVorgaengeDetails
              :defaultTab="2"
              :reiseterminObj="reiseterminObj"
              :isReiseterminInReleasePruefung="isReiseterminInReleasePruefung || isInTransferplanung"
              :isInTransferplanung="isInTransferplanung"
              :gueltigeKonfiguration="reiseterminObj?.gueltigeKonfiguration"
            ></ReiseterminCardVorgaengeDetails>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.more-btn {
  cursor: pointer;
}
</style>

<script>
import ReiseterminCardCommentBase from '@/components/produkte/reisetermine/reisetermin-card-comment-base.vue';
import ReiseterminCardVorgaengeDetails from '@/components/produkte/reisetermine/reisetermin-card-vorgaenge-details.vue';
import ReiseterminViewCardFlugdaten from '@/components/produkte/reisetermine/reisetermin-card-flugdata.vue';
import ReiseterminViewCardSheetdata from '@/components/produkte/reisetermine/reisetermin-card-sheetdata.vue';
import ReiseterminCardAufgaben from '@/components/produkte/reisetermine/reisetermin-card-aufgaben.vue';

export default {
  name: 'ReisetermineViewComponent',
  components: {
    ReiseterminCardVorgaengeDetails,
    ReiseterminCardCommentBase,
    ReiseterminViewCardFlugdaten,
    ReiseterminViewCardSheetdata,
    ReiseterminCardAufgaben,
  },
  props: {
    reiseterminObj: Object,
  },
  data() {
    return {};
  },
  computed: {
    isTerminNach24() {
      if (!this.reiseterminObj) {
        return false;
      }
      return new Date(this.reiseterminObj.startdatum).getFullYear() === 2025;
    },
    isTerminIn24() {
      if (!this.reiseterminObj) {
        return false;
      }
      return new Date(this.reiseterminObj.startdatum).getFullYear() === 2024;
    },
    isReiseterminInReleasePruefung() {
      return this.reiseterminObj?.state === 'Aufgelegt';
    },
    isInTransferplanung() {
      return this.reiseterminObj?.state === 'Aufgelegt';
    },
  },
  methods: {},
};
</script>
